import React from "react";
import car1 from "../Images/car1.jpg";
import car2 from "../Images/car2.jpg";
import car3 from "../Images/car3.jpg";

function Carousel() {
  return (
    <>
      <div
        id="carouselExampleAutoplaying"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src={car1} class="cImg d-block w-100" alt="Fyre" />
          </div>
          <div class="carousel-item">
            <img src={car2} class="cImg d-block w-100" alt="Babuls" />
          </div>
          <div class="carousel-item">
            <img src={car3} class="cImg d-block w-100" alt="Babuls" />
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
}

export default Carousel;
