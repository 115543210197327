function Commitment() {
  return (
    <div class="px-4 py-5 my-5 text-center">
      <h1 class="display-5 fw-bold text-body-emphasis">Our Commitment</h1>
      <div class="col-lg-6 mx-auto">
        <p class="lead mb-4">
          Through the synergy of culinary mastery, technology integration, and
          sustainable practices, we aim to redefine South Asian dining
          experiences globally. Our business group is committed to setting new
          standards, fostering growth, and becoming a beacon of excellence that
          showcases the best of South Asian cuisine while embracing the future
          with confidence and innovation.
        </p>
      </div>
    </div>
  );
}

export default Commitment;
