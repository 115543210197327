import Concept from "./Concept";

function CreateCard(conceptItem) {
  return (
    <div class="p-3 col-lg-4 col-md-6 col-sm-12">
      <Concept
        key={conceptItem.key}
        img={conceptItem.img}
        title={conceptItem.title}
        description={conceptItem.description}
        website={conceptItem.website}
      />
    </div>
  );
}

export default CreateCard;
