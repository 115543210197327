import BabulsLogo from "../Images/Babuls_Logo.png";
import BabulsExLogo from "../Images/BabulsEx_Logo.png";
import MinaraLogo from "../Images/Minara-logo.png";
import MinaraGroupLogo from "../Images/Minara-g-logo.png";
import YuzeLogo from "../Images/yuzelogo.png";
import YuzeStudiosLogo from "../Images/yuze-studios.png";

const Concepts = [
  {
    key: 1,
    title: "Babul's",
    img: BabulsLogo,
    description:
      "Experience the rich flavors of South Asian traditional and street food in a modern and vibrant casual dining setting, where art meets coffee, a wide array of bespoke cocktails, and technology enhances customer journey.",
    website: "https://babuls.co.uk/"
  },
  {
    key: 2,
    img: YuzeLogo,
    title: "Yuze",
    description:
      "Our company specializes in Epos solutions designed exclusively for the hospitality industry, offering seamless integrated payment services and an award-winning online ordering platform, all at unbeatable industry rates.",
    website: "https://yuze.uk/"
  },
  {
    key: 3,
    img: MinaraLogo,
    title: "Minara Foods",
    description:
      "Wholesalers serving the South Asian restaurant industry, we meticulously source the finest products from the Indian sub-continent and offer tailored recipe development services, ensuring culinary excellence for your restaurant.",
    website: "https://www.minara.uk/"
  },
  {
    key: 4,
    img: BabulsExLogo,
    title: "Babul's Express",
    description:
      "Indulge in the perfection of Babul's South Asian food, crafted to perfection for takeout, complemented by their incredible cocktails. Our state-of-the-art delivery management system ensures lightning-fast deliveries, guaranteeing a remarkable dining experience.",
    website: "https://babuls.co.uk/"
  },
  {
    key: 5,
    img: YuzeStudiosLogo,
    title: "Yuze Studios",
    description:
      "Expert social media marketing specialists, crafting marketing strategies for all enterprises. Our services include photography, videography, and end-to-end film production and distribution, empowering your brand to shine in the digital landscape.",
    website: "https://yuze.uk/"
  },
  {
    key: 6,
    img: MinaraGroupLogo,
    title: "Minara Group of Companies",
    description:
      "Minara Group of Companies is a diverse collection of boutique companies, each specializing in unique niche markets, spanning from the travel industry to fashion and cosmetics, offering specialized services and products to cater to your distinct needs.",
    website: "https://www.minara.uk/"
  }
];

export default Concepts;
