import React from "react";

function Concept(props) {
  return (
    <>
      <div class="card ventures">
        <img src={props.img} class="card-img-top" alt="..." />
        <div class="card-body">
          <h5 class="card-title">
            <b>{props.title}</b>
          </h5>
          <p class="card-text">{props.description}</p>
          <a href={props.website} class="btn btn-light rounded-pill">
            <b>Visit Us Here</b>
          </a>
        </div>
      </div>
    </>
  );
}

export default Concept;
