import React, { useState } from "react";

function Maps() {

  const [mapUrl, setMapUrl] = useState(
    "https://www.google.com/maps/embed/v1/place?key=AIzaSyAG4fgXZ-6-iAhSiARHrxreHI5IrzEMxcA&q=Babuls+Darlington"
  );

  function darlingtonMap() {
    return setMapUrl(
      "https://www.google.com/maps/embed/v1/place?key=AIzaSyAG4fgXZ-6-iAhSiARHrxreHI5IrzEMxcA&q=Babuls+Darlington"
    );
  }
  function barneyMap() {
    return setMapUrl(
      "https://www.google.com/maps/embed/v1/place?key=AIzaSyAG4fgXZ-6-iAhSiARHrxreHI5IrzEMxcA&q=Babuls+Barnard+castle"
    );
  }

  function yuzeMap() {
    return setMapUrl(
      "https://www.google.com/maps/embed/v1/place?key=AIzaSyAG4fgXZ-6-iAhSiARHrxreHI5IrzEMxcA&q=Yuze+Darlington"
    );
  }

  return (
    <>
      <div class="container">
        <h1 class="mb-3"> Our Locations </h1>
        <div class="btn-group" role="group">
          <button
            onClick={darlingtonMap}
            type="button"
            class="btn btn-outline-warning"
          >
            Babul's Darlington
          </button>
          <button
            onClick={yuzeMap}
            type="button"
            class="btn btn-outline-warning"
          >
            Yuze Darlington
          </button>
          <button
            onClick={barneyMap}
            type="button"
            class="btn btn-outline-warning"
          >
            Babul's Barnard Castle
          </button>
        </div>
        <br />
        <br />
        <iframe
          class="map"
          title="Babuls"
          src={mapUrl}
          width="100%"
          height="450"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
}

export default Maps;
