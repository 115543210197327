import "./styles.css";
import Carousel from "./Components/Carousel";
// import Klogo from "./Images/KLogo-N13.png";
import Klogo1 from "./Images/KhondokarLogo.png";
import Concepts from "./Components/Concepts";
import CreateCard from "./Components/CreateCard";
import About from "./Components/About";
import CoreValues from "./Components/CoreValues";
import Maps from "./Components/Maps";
import Commitment from "./Components/Commitment";
import Footer from "./Components/Footer";

export default function App() {
  return (
    <div className="App">
      <img class="klogo1" height="125px" src={Klogo1} alt="klogo" />

      <Carousel />
      <About />
      <div class="container">
        <h2>
          {" "}
          <b> Our Ventures </b>{" "}
        </h2>
        <div class="row">{Concepts.map(CreateCard)}</div>
      </div>
      <CoreValues />
      <Maps />
      <Commitment />
      <Footer />
    </div>
  );
}
