function CoreValues() {
  return (
    <div class="container px-4 py-5" id="hanging-icons">
      <h2 class="pb-2 border-bottom">Core Values</h2>
      <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
        <div class="col d-flex align-items-start">
          <div class="icon-square text-body-emphasis bg-body-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
            <img
              width="64"
              height="64"
              src="https://img.icons8.com/arcade/64/bowl-with-spoon.png"
              alt="bowl-with-spoon"
            />
          </div>
          <div>
            <h3 class="fs-2 text-body-emphasis">Culinary Excellence</h3>
            <p class="core-value-text">
              We honor the rich heritage of South Asian cuisine by promoting
              authenticity, diversity, and exceptional flavors in every dish.
              Our goal is to create a gastronomic journey that captures the
              essence of the region.
            </p>
          </div>
        </div>
        <div class="col d-flex align-items-start">
          <div class="icon-square text-body-emphasis bg-body-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
            <img
              width="50"
              height="50"
              src="https://img.icons8.com/office/40/light-automation.png"
              alt="light-automation"
            />
          </div>
          <div>
            <h3 class="fs-2 text-body-emphasis">Innovation</h3>
            <p class="core-value-text">
              Embracing the future, we merge technology and tradition to create
              seamless processes and elevate the customer experience. We
              constantly explore new cooking techniques, ingredients, and
              presentation styles.
            </p>
          </div>
        </div>
        <div class="col d-flex align-items-start">
          <div class="icon-square text-body-emphasis bg-body-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
            <img
              width="64"
              height="64"
              src="https://img.icons8.com/doodle/48/nature-care.png"
              alt="nature-care"
            />
          </div>
          <div>
            <h3 class="fs-2 text-body-emphasis">Sustainability</h3>
            <p class="core-value-text">
              We are committed to responsible sourcing, reducing waste, and
              minimizing our carbon footprint. By implementing sustainable
              practices across our supply chain, we ensure that the joy of South
              Asian flavors can be savored for generations to come.
            </p>
          </div>
        </div>
      </div>

      <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
        <div class="col d-flex align-items-start">
          <div class="icon-square text-body-emphasis bg-body-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
            <img
              width="66"
              height="66"
              src="https://img.icons8.com/external-smashingstocks-flat-smashing-stocks/66/external-skill-development-elearning-smashingstocks-flat-smashing-stocks.png"
              alt="external-skill-development-elearning-smashingstocks-flat-smashing-stocks"
            />
          </div>
          <div>
            <h3 class="fs-2 text-body-emphasis">Skill Development</h3>
            <p class="core-value-text">
              Recognizing the shortage of skilled professionals in the industry,
              we invest in comprehensive training programs. By nurturing talent,
              we empower individuals to excel in their roles, fostering a
              vibrant workforce.
            </p>
          </div>
        </div>
        <div class="col d-flex align-items-start">
          <div class="icon-square text-body-emphasis bg-body-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
            <img
              width="64"
              height="64"
              src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/external-efficiency-productivity-flaticons-lineal-color-flat-icons-2.png"
              alt="external-efficiency-productivity-flaticons-lineal-color-flat-icons-2"
            />
          </div>
          <div>
            <h3 class="fs-2 text-body-emphasis">Effeciency</h3>
            <p class="core-value-text">
              Our focus on technology-driven solutions allows us to create
              efficient systems that adapt to changing trends and needs. By
              embracing digital innovation, we ensure that our partners and
              clients remain at the forefront of the industry.
            </p>
          </div>
        </div>
        <div class="col d-flex align-items-start">
          <div class="icon-square text-body-emphasis bg-body-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
            <img
              width="64"
              height="64"
              src="https://img.icons8.com/external-flat-geotatah/50/external-diversity-lgbtq-community-flat-flat-geotatah-4.png"
              alt="external-diversity-lgbtq-community-flat-flat-geotatah-4"
            />
          </div>
          <div>
            <h3 class="fs-2 text-body-emphasis">Diversity</h3>
            <p class="core-value-text">
              We celebrate the diversity within our team and the communities we
              serve. By fostering an inclusive environment, we encourage
              collaboration and creativity that reflect the multifaceted nature
              of South Asian culture.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoreValues;
