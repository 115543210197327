import Group_Logo from "../Images/Group_Logo.png";

function About() {
  return (
    <div class="container">
      <div class="card w-100">
        <div class="card-header pt-3">
          <h4>
            <b>About Us</b>
          </h4>
        </div>
        <div class="card-body">
          <h1 class="about-title card-title ">
            <img class="aboutImg" src={Group_Logo} alt="klogo" />
          </h1>
          <br />
          <h4 class="card-text">
            <b>
              "Empowering South Asian Gastronomy: Uniting Culinary Excellence,
              Technology, and Sustainable Growth"
            </b>
          </h4>
          <br />
          <h5 class="card-text about-text">
            At Khondokar Group, we are dedicated to revolutionizing the South
            Asian restaurant industry by seamlessly integrating tradition,
            innovation, and sustainability. Our mission is to elevate the
            culinary experience, enhance supply chain efficiency, and bridge the
            gap in skilled workforce through cutting-edge technology and
            management software solutions.
          </h5>
        </div>
      </div>
    </div>
  );
}

export default About;
